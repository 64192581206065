import { PureComponent } from "react";
import { merge } from "./merge";
import AccessMap, { ADJACENCY_SELECTION_MODE, NO_SELECTION_MODE, SEGMENT_SELECTION_MODE } from "./map";
import { DefaultColorScheme } from './color';
import { Map } from "immutable";

class AdjacenciesViewer extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            "segmentRecords": [],
            "viewport": {
                "latitude": 47.60663847499262,
                "longitude": -122.33468081908542,
                "zoom": 14,
            },
            "sectors": [],
            "adjacencies": [],
        }


        this.setViewport = (viewport) => {
            this.setState({ "viewport": viewport });
        }


        this.getSectorsPromise = (grid) => {
            return this.props.gridClient.getSectors(grid).then((sectorRes) => {
                const reducer = (numberedSectors, sector) => {
                    numberedSectors[sector.id] = sector;
                    return numberedSectors;
                };

                const sectors = sectorRes.data.reduce(reducer, []);
                return { "sectors": sectors };
            });
        };

        this.getAdjacenciesPromise = (id) => {
            return this.props.gridClient.getAdjacenciesMap(id).then((res) => {
                return { "adjacencies": res.data };
            });
        };

        this.parsePointString = (pointString) => {
            const coordStrings = pointString.split(",");
            const lat = parseFloat(coordStrings[0].trim());
            const lon = parseFloat(coordStrings[1].trim());

            return [lon, lat]
        }
    }

    componentDidMount() {
        Promise.all([
            this.getSectorsPromise(this.props.gridId),
            this.getAdjacenciesPromise(this.props.adjacenciesId)])
            .then((results) => {
                const result = merge(results);
                this.setState(result);
            });
    }

    render() {
        const adjacencies = this.state.adjacencies.filter((adj) => {
            return this.parsePointString(adj.originLocation)[1] < 47.50948902019172;
        });

        return (
            <>
                <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                    <AccessMap
                        doubleClickZoom={false}
                        visible={true}
                        width="auto"
                        height="auto"
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                        colorScheme={new DefaultColorScheme()}
                        viewport={this.state.viewport}
                        setViewport={this.setViewport}
                        selectionMode={ADJACENCY_SELECTION_MODE}
                        expandedSectorTransformations
                        ={[]}
                        baselineCenterPoints
                        ={[]}
                        baselineCenterSectors
                        ={[]}
                        minRouteZoom={0}
                        busy={false}
                        sectorOutlines={this.state.sectors}
                        adjacencies={adjacencies}
                        calculationCenterSectors={[]}
                        calculationCenterPoints={[]}
                        segmentTransformations={Map({})}
                    />
                </div >
            </>
        )
    }
}

export default AdjacenciesViewer