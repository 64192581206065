import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import AccessMap, { NO_SELECTION_MODE } from './map'
import { DefaultColorScheme } from './color';
import Container from 'react-bootstrap/Container'


import naturalCompare from 'string-natural-compare'

import { List, Map } from "immutable"

class NetworkViewer extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            "viewport": {
                "latitude": 47.60663847499262,
                "longitude": -122.33468081908542,
                "zoom": 14,
            },
            "name": "",
            "calendars": List(),
            "selectedCalendar": undefined,
            "routes": List(),
            "selectedRoute": undefined,
            "direction": 0,
            "routings": List(),
            "routingIndex": 0,
            "routingStops": List(),
            "linestrings": {},
            "routesForRoutings": {},
            "schedulesMap": Map(),
            "trips": List(),

        }

        this.setViewport = (viewport) => {
            this.setState({ "viewport": viewport });
        }

        this.setSector = (sectorId) => { };

        this.setExpandedSector = (sectorId) => { }

        this.clearExpandedSector = () => { };

        this.setTransformationType = (type) => { };

        this.setDuration = (duration) => { }

        this.setBaselineDuration = (duration) => { }

        this.colorScheme = new DefaultColorScheme()

        this.getLinestringsPromise = (networkId) => {
            return this.props.unRideIt.getLinestrings(networkId).then((res) => {
                return {
                    "linestrings": res.data

                }
            });
        }

        this.getRoutesForRoutingPromise = (networkId) => {
            return this.props.unRideIt.getRoutesForRoutings(networkId).then((res) => {
                return {
                    "routesForRoutings": res.data
                }
            });
        }

        this.getNetworkDataPromise = (networkId) => {
            return this.props.unRideIt.getNetwork(networkId).then(res => {
                const name = res.data.name;
                return { "name": name }
            })
        }

        this.getRoutesPromise = (networkId) => {
            return this.props.unRideIt.getNetworkRoutes(networkId).then(res => {
                const routes = List(res.data)
                return { "routes": routes }
            });
        };

        this.getCalendarsPromise = (networkId) => {
            return this.props.unRideIt.getNetworkCalendars(networkId).then(res => {
                const calendars = List(res.data);
                return { "calendars": calendars };
            });
        };

        this.getRoutingsPromise = (networkId, route, direction) => {
            return this.props.unRideIt.getNetworkRoutings(networkId, route, direction).then(res => {
                const routings = List(res.data);
                return { "routings": routings };
            });
        }

        this.getSchedulesPromise = (networkId, routingId) => {
            return this.props.unRideIt.getNetworkSchedules(networkId, routingId).then(res => {
                const schedules = Map(res.data.map(schedule => [schedule.scheduleId, schedule]));
                return { "schedulesMap": schedules };
            });
        }

        this.getTripsPromise = (networkId, routingId, calendar) => {
            return this.props.unRideIt.getNetworkRoutingTrips(networkId, routingId, calendar).then(res => {
                const trips = List(res.data)
                return { "trips": trips }
            });
        }

        this.getStopsPromise = (routing) => {
            return this.props.unRideIt.getStopDetails(routing.stopIds)
                .then((res) => {
                    const stopMap = res.data;
                    const stops = routing.stopIds.map(stopId => {
                        const stop = stopMap[stopId];
                        return {
                            "id": stopId,
                            "location": stop.stopLocation,
                            "name": stop.stopName
                        };
                    });
                    return { "routingStops": stops };
                });
        };


        this.switchRoute = (routeEvent) => {
            const route = routeEvent.target.value;
            this.getRoutingsPromise(
                this.props.networkId, route, 0)
                .then(state => {
                    return Promise.resolve({
                        "routings": state["routings"],
                        "selectedRoute": route,
                        "routingIndex": 0,
                        "direction": 0,
                    })
                }).then(state => {
                    const routing = state["routings"].get(state["routingIndex"])
                    const routingId = routing["routingId"];
                    return Promise.all([
                        Promise.resolve(state),
                        this.getStopsPromise(routing),
                        this.getSchedulesPromise(
                            this.props.networkId,
                            routingId),
                        this.getTripsPromise(
                            this.props.networkId,
                            routingId,
                            this.state.selectedCalendar)
                    ])
                }).then(states => {
                    const newState = states.reduce((flat, statePart) => {
                        return Object.assign({}, flat, statePart);
                    }, {});
                    this.setState(newState);
                });
        };

        this.switchCalendar = (calendarEvent) => {

            const calendar = calendarEvent.target.value;

            const promises = [Promise.resolve({ "selectedCalendar": calendar })]

            if (this.state.routings.size > 0 && this.state.routingIndex !== undefined) {
                const routing = this.state.routings.get(this.state.routingIndex);
                const routingId = routing["routingId"]
                promises.push(this.getTripsPromise(
                    this.props.networkId,
                    routingId,
                    calendar)
                )
            }

            Promise.all(promises).then(states => {
                const newState = states.reduce((flat, statePart) => {
                    return Object.assign({}, flat, statePart);
                }, {});
                this.setState(newState);
            });
        }

        this.switchDirection = (directionEvent) => {
            const direction = this.state.direction ? 0 : 1
            this.getRoutingsPromise(
                this.props.networkId,
                this.state.selectedRoute,
                direction).then(state => {
                    const routing = state["routings"].get(0)
                    const routingId = routing["routingId"];

                    return Promise.all([
                        Promise.resolve(state),
                        this.getStopsPromise(routing),
                        this.getSchedulesPromise(
                            this.props.networkId,
                            routingId),
                        this.getTripsPromise(
                            this.props.networkId,
                            routingId,
                            this.state.selectedCalendar),
                        Promise.resolve({
                            "direction": direction,
                            "routingIndex": 0,
                        })])
                }).then(states => {
                    const newState = states.reduce((flat, statePart) => {
                        return Object.assign({}, flat, statePart);
                    }, {});
                    this.setState(newState);
                })
        };

        this.switchRouting = (routingIndex) => {
            const routing = this.state.routings.get(routingIndex);
            const routingId = routing["routingId"]
            return Promise.all([
                this.getStopsPromise(routing),
                this.getSchedulesPromise(
                    this.props.networkId,
                    routingId),
                this.getTripsPromise(
                    this.props.networkId,
                    routingId,
                    this.state.selectedCalendar),
                Promise.resolve({
                    "routingIndex": routingIndex
                })
            ]).then(states => {
                const newState = states.reduce((flat, statePart) => {
                    return Object.assign({}, flat, statePart);
                }, {});
                this.setState(newState);
            })
        }
    }

    componentDidMount() {
        Promise.all([
            this.getLinestringsPromise(this.props.networkId),
            this.getRoutesForRoutingPromise(this.props.networkId),
            this.getNetworkDataPromise(this.props.networkId),
            this.getRoutesPromise(this.props.networkId),
            this.getCalendarsPromise(this.props.networkId)
        ])
            .then((states) => {
                const newState = states.reduce((flat, statePart) => {
                    return Object.assign({}, flat, statePart);
                }, {});
                this.setState(newState);
            });
    }



    render() {
        const sortedRoutes = this.state.routes.sort((r1, r2) => naturalCompare(r1, r2));

        let linestrings = this.state.linestrings;
        let routesForRoutings = this.state.routesForRoutings;
        if (!this.state.routings.isEmpty()) {
            const routing = this.state.routings.get(this.state.routingIndex);
            const routingId = routing.routingId;
            if (Object.keys(this.state.linestrings).length > 0) {
                linestrings = { [routingId]: this.state.linestrings[routingId] }
                routesForRoutings = {}
            }

        }

        const stops = this.state.routingStops.reduce((accumulator, data) => {
            accumulator[data.id] = { "stopName": data.name, "stopLocation": data.location }
            return accumulator;
        }, {});
        return (
            <Container fluid style={{
                "display": "flex",
                "flexDirection": "column",
                "flexGrow": 1,
                "height": "100vh"
            }}>
                <Row>
                    <h1>{this.state.name}</h1>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Form.Select
                                value={this.state.selectedCalendar || ""}
                                onChange={this.switchCalendar}>
                                <option value="" disabled>Calendars</option>
                                {this.state.calendars.map((calendar) => {
                                    return (
                                        <option key={calendar}
                                            value={calendar}>{calendar}
                                        </option>);
                                })}
                            </Form.Select>
                            <Form.Select
                                value={this.state.selectedRoute || ""}
                                onChange={this.switchRoute}>
                                <option value="" disabled>Routes</option>
                                {sortedRoutes.map((route) => {
                                    return (
                                        <option key={route}
                                            value={route}>{route}
                                        </option>);
                                })}
                            </Form.Select>
                        </Form>
                    </Col>
                    <Col>
                        <Button onClick={this.switchDirection}>
                            Switch Direction ({this.state.direction})
                        </Button>
                    </Col>
                    <Col>
                        <RoutingSelector
                            index={this.state.routingIndex}
                            routings={this.state.routings}
                            switchRouting={this.switchRouting} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ScheduleTable
                            routingStops={this.state.routings.isEmpty() ?
                                List() : this.state.routingStops}

                            networkId={this.props.networkId}
                            unRideIt={this.props.unRideIt}

                            schedulesMap={this.state.schedulesMap}
                            routeName={this.state.selectedRoute}
                            direction={this.state.direction}

                            trips={this.state.trips} />
                    </Col>
                </Row>
                <Row style={{ minHeight: "600px" }}>
                    <Col>
                        <AccessMap
                            minRouteZoom={0}
                            stops={stops}
                            linestrings={linestrings}
                            routesForRoutings={routesForRoutings}
                            gridId={undefined}
                            colorScheme={this.colorScheme}
                            sectorTransformations={[]}
                            expandedSectorTransformations
                            ={[]}
                            transformationType={undefined}
                            calculationCenterPoints
                            ={[]}
                            baselineCenterPoints
                            ={[]}
                            calculationCenterSectors
                            ={[]}
                            baselineCenterSectors
                            ={[]}
                            setSector={this.setSector}
                            selectedSector={this.state.selectedSector}
                            expandedSector={this.state.expandedSector}
                            loadingExpansion={false}
                            setExpandedSector={this.setExpandedSector}
                            clearExpandedSector={this.clearExpandedSector}
                            unGridIt={this.props.unGridIt}
                            showSelect={false}
                            selectionMode={NO_SELECTION_MODE}
                            viewport={this.state.viewport}
                            setViewport={this.setViewport}
                            isComparison={false}
                            isExhaustive={false}
                            setTransformationType={this.setTransformationType}
                            boundaryFindingMode={false}
                            busy={!this.state.name} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

class RoutingSelector extends React.PureComponent {

    constructor(props) {
        super(props);

        this.incrementIndex = () => {
            const numRoutings = this.props.routings.size
            const index = this.props.index;
            const max = numRoutings - 1;
            if (index < max) {
                this.props.switchRouting(index + 1);
            }
        };

        this.decrementIndex = () => {
            const index = this.props.index;
            if (index > 0) {
                this.props.switchRouting(index - 1);
            }
        };
    }

    render() {
        const numRoutings = this.props.routings.size
        return (<>
            <ButtonGroup>
                <Button onClick={this.decrementIndex}>◀</Button>
                {(numRoutings !== 0) ?
                    <Button>Variant {this.props.index + 1} of {numRoutings}</Button> : null}
                <Button onClick={this.incrementIndex}>▶</Button>
            </ButtonGroup>
            {(numRoutings !== 0 || this.props.index === undefined) ? this.props.routings.get(this.props.index).routingId : null}
        </>
        );
    }
}



class ScheduleTable extends React.PureComponent {

    toSeconds(timeString) {
        const parts = timeString.split(":");
        return (parseInt(parts[0]) * 60 * 60) + (parseInt(parts[1]) * 60) + parseInt(parts[2])
    }

    toTime(pastMidnight) {
        const hours = Math.trunc(pastMidnight / (60 * 60))
        const minutes = Math.trunc((pastMidnight % (60 * 60)) / 60)
        const seconds = pastMidnight % 60

        return String(hours).padStart(2, '0') + ":" +
            String(minutes).padStart(2, '0') + ":" +
            String(seconds).padStart(2, '0')
    }

    render() {
        const sortedTrips = this.props.trips.sort((t1, t2) => t1["startTime"].localeCompare(t2["startTime"]));
        return (

            <Table bordered responsive striped size="sm">
                <tbody>
                    {
                        this.props.routingStops.map((stop, index) => {
                            const rowKey = "stop" + index
                            return (<tr key={rowKey}>
                                <td>{index}</td>
                                <td>{stop["name"]} ({stop["id"]})</td>
                                {sortedTrips.map(trip => {
                                    const schedule = this.props.schedulesMap.get(trip["scheduleId"]);
                                    const stop = schedule["timing"][index];
                                    const arrivalOffset = stop["arrivalOffset"];
                                    const tripStart = this.toSeconds(trip["startTime"]);

                                    const time = this.toTime(arrivalOffset + tripStart)
                                    return (


                                        <td key={rowKey + time}>{time}
                                        </td>)
                                })}
                            </tr>)
                        })
                    }
                </tbody>
            </Table>);
    }
}



export default NetworkViewer