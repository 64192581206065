import axios from 'axios';

class UnGridItClient {

    static BEARER_PREFIX = "Bearer "

    constructor() {
        this.host = process.env.REACT_APP_UNGRIDIT_ENDPOINT;
    }

    getBaseMaps() {
        const url = this.host + "/basemaps";
        return axios.get(url);
    }

    getGrids() {
        const url = this.host + "/grids";
        return axios.get(url);
    }

    createGrid(tokenProvider, name, boundaries, resolution) {
        const request = {
            "name": name,
            "boundaryIds": boundaries,
            "resolutionMeters": resolution
        };
        const url = this.host + "/grids:boundary";

        return axios.post(url, request, this.createAuthConfig(tokenProvider));
    }

    getGrid(gridId) {
        const url = this.host + "/grids/" + gridId;
        return axios.get(url);
    }

    getSectors(gridId) {
        const url = this.host + "/grids/" + gridId + "/sectors";
        return axios.get(url);
    }

    getAdjacenciesMap(id) {
        const url = "https://brm6rvvpcpazumjobo3wvrm4z40ugyyg.lambda-url.us-east-2.on.aws/?adjacencies-id=" + id;
        return axios.get(url);
    }

    getProjection(gridId, point) {
        const url = this.host + "/grids/" + gridId + "/projections/" + point;
        return axios.get(url);
    }

    getBoundaries(west, south, east, north) {
        const url = this.host + "/boundaries?westLon=" + west
            + "&southLat=" + south
            + "&eastLon=" + east
            + "&northLat=" + north
        return axios.get(url);
    }

    createAuthConfig(tokenProvider) {
        return {
            headers: {
                "Authorization": UnGridItClient.BEARER_PREFIX
                    + tokenProvider.getBearerToken()
            }
        }
    }

}

export default UnGridItClient;
