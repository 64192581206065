import deepmerge from 'deepmerge'

export function deepDictMerge(into, newPart) {
    return deepmerge(into, newPart, { isMergeableObject: (o) => (o !== undefined) && (o.constructor === Object) });
}

export function merge(states) {
    const newState = states.reduce((flat, statePart) => {
        return deepDictMerge(flat, statePart);
    }, {});
    return newState;
}