import { deepDictMerge, merge } from './merge'
import {
    isEntirelyExhaustive,
    getReachCountsPromise,
} from './calculation'
import { getExpandedReachCountsPromise, getExpandedTransformationsPromise } from "./expansion";
import { getTransformationMerge, getTransformationsForContext, getTransformedValues } from './transformer';

export function setExpansionUpdateInterval(component) {
    const interval = setInterval(() => {
        if (component.state.loadingExpansion && (component.state.expandedSector !== undefined)) {
            setExpandedSector(component, component.state.expandedSector)
        }
    }, 10000);
    component.setState({ "expansionUpdateInterval": interval })
}

export function setDurationUpdateInterval(component,
    duration, calculationId, calculationPath, calculationField, inServiceField) {
    const interval = setInterval(() => {
        updateAggregationsForDuration(component,
            duration,
            calculationId,
            calculationPath,
            calculationField,
            inServiceField)

    }, 10000);
    component.setState({ "durationUpdateInterval": interval });
}

export function setViewport(component, viewport) {
    component.setState({ "viewport": viewport });
}

export function setSector(component, sector) {
    component.setState({ "selectedSector": sector });
}

function isExpansionComplete(calculation, baseline) {
    let completed = true;
    if (calculation.get('id')) {
        const status = calculation.get('expansionStatus');
        completed &= ((status === "COMPLETE") || (status === "ERROR"));
    }
    if (baseline.get('id')) {
        const status = baseline.get('expansionStatus');
        completed &= ((status === "COMPLETE") || (status === "ERROR"));
    }
    return completed;
}

function isDurationUpdateComplete(calculation, expandedSector) {
    let completed = true;
    if (calculation.get('id') && (expandedSector !== undefined)) {
        const status = calculation.get('expansionStatus');
        completed &= ((status === "COMPLETE") || (status === "ERROR"));
    }
    if (calculation.get('id')) {
        const status = calculation.get('status');
        completed &= ((status === "COMPLETE") || (status === "ERROR"));
    }
    return completed;
}

export function setExpandedSector(component, sector) {
    setLoadingExpansion(component)
    const sectorIndex = sector - 1;
    const promises = [];
    const baselineId = component.state.baseline.get('id');
    if (component.state.calculation.get('id')) {
        promises.push(getExpandedReachCountsPromise(
            component,
            component.state.calculation.get('id'),
            sectorIndex,
            "calculation",
            component.state.calculation.get("selectedDuration")
        ));
    }
    if (baselineId) {
        promises.push(getExpandedReachCountsPromise(
            component,
            baselineId,
            sectorIndex,
            "baseline",
            component.state.baseline.get("selectedDuration")
        ))
    }
    Promise.all(promises).then(states => {
        const update = states.reduce((flat, statePart) => {
            return deepDictMerge(flat, statePart);
        }, {});

        const newCalculation = component.state.calculation.merge(
            update.calculation);
        const newBaseline = component.state.baseline.merge(
            update.baseline);

        const loadingExpansion = !isExpansionComplete(newCalculation, newBaseline);

        const newState = {
            "loadingExpansion": loadingExpansion,
            "expandedSector": sector,
            "calculation": newCalculation,
            "baseline": newBaseline
        }
        if (!loadingExpansion) {
            clearInterval(component.state.expansionUpdateInterval);
            newState["expansionUpdateInterval"] = undefined;
        } else if (component.state.expansionUpdateInterval === undefined) {
            setExpansionUpdateInterval(component);
        }

        return newState
    }).then((calculationState) => {
        const transformationsPromise = getExpandedTransformationsPromise(
            component, calculationState, "calculation");
        let baselineTransformationsPromise;
        if (baselineId) {
            baselineTransformationsPromise = getExpandedTransformationsPromise(
                component, calculationState, "baseline");
        } else {
            baselineTransformationsPromise = Promise.resolve({});
        }
        return Promise.all([Promise.resolve(calculationState),
            transformationsPromise, baselineTransformationsPromise]);
    }).then(states => {
        const newCalculation = states.reduce((accumulator, current) => {
            if (current) {
                return accumulator.merge(current.calculation);
            }
            return accumulator;
        }, component.state.calculation);

        const newBaseline = states.reduce((accumulator, current) => {
            if (current) {
                return accumulator.merge(current.baseline);
            }
            return accumulator;
        }, component.state.baseline);

        const newState = merge(states);
        newState.calculation = newCalculation;
        newState.baseline = newBaseline;

        if (baselineId) {
            newState.expandedSectorTransformations
                = getTransformationMerge(component.state.sectors,
                    newState.calculation.get("expandedSectorTransformations"),
                    newState.baseline.get("expandedSectorTransformations"));
        } else {
            newState.expandedSectorTransformations = newCalculation.get(
                "expandedSectorTransformations");
        }
        const existingTransformation = component.transformationType;

        const newValidTransformations = getTransformationsForContext(
            false, false, !!baselineId, true, isEntirelyExhaustive(
                component.state.calculation,
                component.state.baseline));

        if (!newValidTransformations.includes(existingTransformation)) {
            newState.transformationType = newValidTransformations[0];
        }
        component.setState(newState);
    });
}

export function updateAggregationsForDuration(
    component, duration, calculationId, calculationPath, calculationField, serviceTimeField) {
    setChangingDuration(component);
    const promises = [
        Promise.resolve({
            [calculationField]: {
                "selectedDuration": duration
            }
        }),
        getInServiceTimePromise(component, calculationId, calculationPath, duration, serviceTimeField),
        getReachCountsPromise(component, calculationId, calculationPath,
            calculationField, duration)
    ];

    if (component.state.expandedSector !== undefined) {
        const sectorIndex = component.state.expandedSector - 1;

        promises.push(getExpandedReachCountsPromise(
            component, calculationId, sectorIndex,
            calculationField, duration));
    }

    Promise.all(promises)
        .then(states => {
            const inputState = merge(states);

            let newCalc = component.state[calculationField].merge(
                inputState[calculationField]);

            const calculationTransformations = getTransformedValues(
                component.state.sectors, newCalc.get("sectorValues"),
                newCalc.get("times") * newCalc.get("centerCount"));
            newCalc = newCalc.merge(
                { "sectorTransformations": calculationTransformations });

            let transformations;
            if (calculationField === "calculation" && !component.state.baseline.get("id")) {
                transformations = calculationTransformations;
            } else if (calculationField === "calculation" && !!component.state.baseline.get("id")) {
                transformations = getTransformationMerge(
                    component.state.sectors, calculationTransformations,
                    component.state.baseline.get("sectorTransformations"));
            } else {
                transformations = getTransformationMerge(
                    component.state.sectors,
                    component.state.calculation.get("sectorTransformations"),
                    calculationTransformations);

            }

            const changingDuration = !isDurationUpdateComplete(newCalc, component.state.expandedSector);
            const newState = {
                "sectorTransformations": transformations,
                [serviceTimeField]: inputState[serviceTimeField],
                "changingDuration": changingDuration,
            };

            if (!changingDuration) {
                clearInterval(component.state.durationUpdateInterval);
                newState['durationUpdateInterval'] = undefined;
            } else if (component.state.durationUpdateInterval === undefined) {
                setDurationUpdateInterval(component,
                    duration,
                    calculationId,
                    calculationPath,
                    calculationField,
                    serviceTimeField);
            }

            if (component.state.expandedSector !== undefined) {
                const expandedCalculationTransformations
                    = getTransformedValues(
                        component.state.sectors,
                        newCalc.get("expandedSectorValues"),
                        newCalc.get("times"));
                newCalc = newCalc.merge({
                    "expandedSectorTransformations":
                        expandedCalculationTransformations
                });

                let expandedTransformations;
                if (calculationField === "calculation" && !component.state.baseline.get("id")) {
                    expandedTransformations = expandedCalculationTransformations;
                } else if (calculationField === "calculation" && !!component.state.baseline.get("id")) {
                    expandedTransformations = getTransformationMerge(
                        component.state.sectors,
                        expandedCalculationTransformations,
                        component.state.baseline.get("expandedSectorTransformations"))
                } else {
                    expandedTransformations = getTransformationMerge(
                        component.state.sectors,
                        component.state.calculation.get("expandedSectorTransformations"),
                        expandedCalculationTransformations);
                }
                newState["expandedSectorTransformations"] = expandedTransformations;
            }

            newState[calculationField] = newCalc;
            component.setState(newState);
        })

}


export function clearExpandedSector(component) {
    component.setState({ "expandedSector": undefined })
}

export function setLoadingExpansion(component) {
    component.setState({ "loadingExpansion": true })
}

export function clearLoadingExpansion(component) {
    component.setState({ "loadingExpansion": false })
}

export function setChangingDuration(component) {
    component.setState({ "changingDuration": true })
}

export function clearChangingDuration(component) {
    component.setState({ "clearChangingDuration": false })
}

export function setTransformationType(component, type) {
    component.setState({ "transformationType": type });
}

export function getInServiceTimePromise(component, calculationId, path, duration, serviceTimeField) {
    return component.props.unScoreIt.getServiceTime(calculationId, path, duration).then(res => {
        return {
            [serviceTimeField]: parseInt(res.data)
        };
    })
}