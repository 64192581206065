
import React from 'react';
import Table from 'react-bootstrap/Table';
import { Card } from 'react-bootstrap';

import {
    INBOUND_COUNT_TRANSFORMATION,
    INBOUND_RATIO_TRANSFORMATION,
    INBOUND_Z_SCORE,
    OUTBOUND_COUNT_TRANSFORMATION,
    OUTBOUND_RATIO_TRANSFORMATION,
    OUTBOUND_Z_SCORE,
    Z_SCORE_MEAN,
    getTransformationsForContext
} from './transformer';
import { isExhaustive } from './calculation';

function getSectorInformation(colorScheme, calculation,
    baseline, selectedSector, sectors, setSector, unScoreIt,
    expandedSector) {

    const sector = (selectedSector !== undefined)
        ? sectors[selectedSector - 1] : undefined;

    const sectorBounds = sector ? sector.bounds : undefined;

    return baseline.get('id') ?
        (<SectorComparisonInformation
            colorScheme={colorScheme}
            calculation={calculation}
            baseline={baseline}
            selectedSector={selectedSector}
            selectedSectorBounds={sectorBounds}
            unScoreItClient={unScoreIt}
            setSector={setSector}
            expandedSector={expandedSector} />) :
        (<SectorInformation
            colorScheme={colorScheme}
            calculation={calculation}
            selectedSector={selectedSector}
            selectedSectorBounds={sectorBounds}
            unScoreItClient={unScoreIt}
            setSector={setSector}
            expandedSector={expandedSector} />);
}

class SectorJourneyPanel extends React.PureComponent {

    render() {
        const sectorTransformations = this.props.sectorTransformations;
        const sectorIndex = this.props.selectedSector - 1;
        const inboundCount = sectorTransformations
            .get(INBOUND_COUNT_TRANSFORMATION).values[sectorIndex].value;
        const outboundCount = sectorTransformations
            .get(OUTBOUND_COUNT_TRANSFORMATION).values[sectorIndex].value;
        const inboundRatio = sectorTransformations
            .get(INBOUND_RATIO_TRANSFORMATION).values[sectorIndex].value;
        const outboundRatio = sectorTransformations
            .get(OUTBOUND_RATIO_TRANSFORMATION).values[sectorIndex].value;
        const composite = inboundCount * outboundCount;
        const mean = Math.sqrt(composite);
        const compositeRatio = inboundRatio * outboundRatio;
        const meanRatio = Math.sqrt(compositeRatio);

        const validTransformations = getTransformationsForContext(
            false, false, false, this.props.isExpanded, this.props.isExhaustive);

        let meanZScore;
        let inboundZScore;
        let outboundZScore;

        if (validTransformations.includes(Z_SCORE_MEAN)) {
            meanZScore = (<b>Z: {sectorTransformations.get(Z_SCORE_MEAN)
                .values[sectorIndex].value.toFixed(4)}</b>);
            inboundZScore = (<b>Z: {sectorTransformations.get(INBOUND_Z_SCORE)
                .values[sectorIndex].value.toFixed(4)}</b>);
            outboundZScore = (<b>Z: {sectorTransformations.get(OUTBOUND_Z_SCORE)
                .values[sectorIndex].value.toFixed(4)}</b>);
        } else {
            meanZScore = null;
            inboundZScore = null;
            outboundZScore = null;
        }

        return (<Table bordered size="sm">
            <tbody>
                <tr>
                    <td>Inbound Completed Journeys</td>
                    <td>{inboundCount.toLocaleString()} ({inboundRatio.toFixed(4)}) {inboundZScore}</td>
                </tr>
                <tr>
                    <td>Outbound Completed Journeys</td>
                    <td>{outboundCount.toLocaleString()} ({outboundRatio.toFixed(4)}) {outboundZScore}</td>
                </tr>
                <tr>
                    <td>Mean</td>
                    <td>{mean.toLocaleString()} ({meanRatio.toFixed(4)}) {meanZScore}</td>
                </tr>
                <tr>
                    <td>Composite Score</td>
                    <td>{composite.toLocaleString()} ({compositeRatio.toFixed(4)})</td>
                </tr>
            </tbody>
        </Table>);
    }
}

class SectorInformation extends React.PureComponent {

    render() {
        const calculation = this.props.calculation;
        const sectorTransformations = calculation.get(
            "sectorTransformations")
        const expandedSectorTransformations = calculation.get(
            "expandedSectorTransformations");
        const expandedSector = this.props.expandedSector;
        const selectedSector = this.props.selectedSector;
        const exhaustive = isExhaustive(calculation)
        let expandedJourneysPanel;
        if (expandedSector !== undefined) {
            expandedJourneysPanel = (
                <Card>
                    <Card.Body>
                        <Card.Title>Relative to Expanded Sector {expandedSector}</Card.Title>
                        <SectorJourneyPanel
                            sectorTransformations={expandedSectorTransformations}
                            selectedSector={selectedSector}
                            isExpanded={true}
                            isExhaustive={false} />
                    </Card.Body>
                </Card>
            );
        } else {
            expandedJourneysPanel = null;
        }

        return (<div>
            <h5>
                <small>{this.props.selectedSectorBounds}</small>
            </h5>
            <SectorJourneyPanel
                selectedSector={selectedSector}
                sectorTransformations={sectorTransformations}
                isExpanded={false}
                isExhaustive={exhaustive}
            />
            {expandedJourneysPanel}
        </div>);
    }
}

function getTransformationRows(transformations, baselineTransformations,
    sectorIndex, isExpanded, isExhaustive, isBaselineExhaustive,
    isComparison) {
    const calculationInboundCount = transformations
        .get(INBOUND_COUNT_TRANSFORMATION).values[sectorIndex].value;
    const calculationOutboundCount = transformations
        .get(OUTBOUND_COUNT_TRANSFORMATION).values[sectorIndex].value;
    const mean = Math.sqrt(calculationInboundCount)
        * Math.sqrt(calculationOutboundCount);

    const calculationInbound = transformations
        .get(INBOUND_RATIO_TRANSFORMATION).values[sectorIndex].value;
    const calculationOutbound = transformations
        .get(OUTBOUND_RATIO_TRANSFORMATION).values[sectorIndex].value;

    const composite = calculationInboundCount
        * calculationOutboundCount;
    const compositeRatio = calculationInbound * calculationOutbound;
    const meanRatio = Math.sqrt(compositeRatio);

    const validTransformations = getTransformationsForContext(
        false, false, isComparison, false, isExpanded, isExhaustive);

    let meanZScore;
    let inboundZScore;
    let outboundZScore;

    if (validTransformations.includes(Z_SCORE_MEAN)) {
        meanZScore = (<b>Z: {transformations.get(Z_SCORE_MEAN)
            .values[sectorIndex].value.toFixed(4)}</b>);
        inboundZScore = (<b>Z: {transformations.get(INBOUND_Z_SCORE)
            .values[sectorIndex].value.toFixed(4)}</b>);
        outboundZScore = (<b>Z: {transformations.get(OUTBOUND_Z_SCORE)
            .values[sectorIndex].value.toFixed(4)}</b>);
    } else {
        meanZScore = null;
        inboundZScore = null;
        outboundZScore = null;
    }

    const baselineInboundCount = baselineTransformations
        .get(INBOUND_COUNT_TRANSFORMATION).values[sectorIndex].value;
    const baselineOutboundCount = baselineTransformations
        .get(OUTBOUND_COUNT_TRANSFORMATION).values[sectorIndex].value;
    const baselineMean = Math.sqrt(baselineInboundCount)
        * Math.sqrt(baselineOutboundCount);

    const baselineInbound = baselineTransformations
        .get(INBOUND_RATIO_TRANSFORMATION).values[sectorIndex].value;
    const baselineOutbound = baselineTransformations.get(
        OUTBOUND_RATIO_TRANSFORMATION).values[sectorIndex].value;

    const baselineComposite = baselineInboundCount * baselineOutboundCount;
    const baselineCompositeRatio = baselineInbound * baselineOutbound;
    const baselineMeanRatio = Math.sqrt(baselineCompositeRatio);

    const baselineValidTransformations = getTransformationsForContext(
        false, false, isComparison, isExpanded, isBaselineExhaustive);

    let baselineMeanZScore;
    let baselineInboundZScore;
    let baselineOutboundZScore;

    if (baselineValidTransformations.includes(Z_SCORE_MEAN)) {
        baselineMeanZScore = (<b>Z: {baselineTransformations.get(
            Z_SCORE_MEAN).values[sectorIndex].value.toFixed(4)}</b>);
        baselineInboundZScore = (<b>Z: {baselineTransformations.get(
            INBOUND_Z_SCORE).values[sectorIndex].value.toFixed(4)}</b>);
        baselineOutboundZScore = (<b>Z: {baselineTransformations.get(
            OUTBOUND_Z_SCORE).values[sectorIndex].value.toFixed(4)}</b>);
    } else {
        baselineMeanZScore = null;
        baselineInboundZScore = null;
        baselineOutboundZScore = null;
    }

    return (
        <>
            <tr>
                <td>
                    Inbound Completed Journeys
                </td>
                <td>
                    {baselineInboundCount.toLocaleString()} ({baselineInbound.toFixed(4)}) {baselineInboundZScore}
                </td>
                <td>
                    {calculationInboundCount.toLocaleString()} ({calculationInbound.toFixed(4)}) {inboundZScore}
                </td>
            </tr>
            <tr>
                <td>
                    Outbound Completed Journeys
                </td>
                <td>
                    {baselineOutboundCount.toLocaleString()} ({baselineOutbound.toFixed(4)}) {baselineOutboundZScore}
                </td>
                <td>
                    {calculationOutboundCount.toLocaleString()} ({calculationOutbound.toFixed(4)}) {outboundZScore}
                </td>
            </tr>
            <tr>
                <td>
                    Mean
                </td>
                <td>
                    {baselineMean.toLocaleString()} ({baselineMeanRatio.toFixed(4)}) {baselineMeanZScore}
                </td>
                <td>
                    {mean.toLocaleString()} ({meanRatio.toFixed(4)}) {meanZScore}
                </td>
            </tr>
            <tr>
                <td>Composite Score</td>
                <td>
                    {(baselineComposite).toLocaleString()} ({baselineCompositeRatio.toFixed(4)})
                </td>
                <td>
                    {(composite).toLocaleString()} ({compositeRatio.toFixed(4)})
                </td>
            </tr>
        </>
    );
}

class SectorComparisonInformation extends React.PureComponent {

    render() {
        const sectorIndex = this.props.selectedSector - 1;
        const calculation = this.props.calculation;
        const calculationName = calculation.get('name');
        const transformations = calculation.get('sectorTransformations');

        const baseline = this.props.baseline;
        const baselineName = baseline.get('name');

        const baselineTransformations =
            baseline.get('sectorTransformations');

        const exhaustive = isExhaustive(calculation);
        const baselineExhaustive = isExhaustive(baseline)

        const comparisonRows = getTransformationRows(transformations,
            baselineTransformations, sectorIndex, false, exhaustive,
            baselineExhaustive, true);

        let expandedComparisonRows;
        if (this.props.expandedSector !== undefined) {
            const expandedTransformations
                = calculation.get("expandedSectorTransformations");
            const baselineExpandedTransformations
                = baseline.get("expandedSectorTransformations");

            const rows = getTransformationRows(expandedTransformations,
                baselineExpandedTransformations, sectorIndex, true, exhaustive,
                baselineExhaustive, true);

            expandedComparisonRows = (
                <>
                    <tr>
                        <th colSpan={3}>For sector {this.props.expandedSector}</th>
                    </tr>
                    {rows}
                </>
            )

        } else {
            expandedComparisonRows = null;
        }

        return (<div>
            <h5><small>{this.props.selectedSectorBounds}</small></h5>
            <Table bordered size="sm">
                <tbody>
                    <tr>
                        <th></th>
                        <th>
                            {baselineName}
                        </th>
                        <th>
                            {calculationName}
                        </th>
                    </tr>
                    {comparisonRows}
                    {expandedComparisonRows}
                </tbody>
            </Table>
        </div>);
    }
}

export default getSectorInformation