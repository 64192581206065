import React from 'react'

import Form from 'react-bootstrap/Form'

import Datetime from 'react-datetime'

class TimeParametersStep extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            "selectionMode": "all-day"
        }

        this.setSelectionMode = (event) => {
            const mode = event.target.value;

            if (mode === "all-day") {
                this.props.clearBaseTime()
            }
            this.setState({ "selectionMode": mode })
        }

        this.setBaseTime = (time) => {
            this.props.setBaseTime(time);
        }
    }

    render() {
        return (
            <div>
                <Form>
                    <Form.Group controlId="day">
                        <Form.Label>Day</Form.Label>
                        <Datetime
                            mode="date"
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            time={this.props.day}
                            onChange={this.props.setDay}
                            closeOnSelect={true} />
                    </Form.Group>
                    <Form.Group controlId="duration">
                        <Form.Label>Time Budget</Form.Label>
                        <Form.Control
                            value={this.props.durationValue}
                            onChange={this.props.setDurationValue} />
                        <Form.Control as="select"
                            value={this.props.durationUnit}
                            onChange={this.props.setDurationUnit}>
                            <option value="H">Hours</option>
                            <option value="M">Minutes</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="baseTime">
                        <Form.Label>All Day/Single Time</Form.Label>
                        <Form.Control as="select"
                            value={this.state.selectionMode}
                            onChange={this.setSelectionMode}>
                            <option value="all-day">All Day</option>
                            <option value="single-time">Single Time</option>
                        </Form.Control>
                        <Datetime
                            inputProps={{ "disabled": this.state.selectionMode === "all-day" }}
                            mode="time"
                            dateFormat={false}
                            timeFormat="HH:mm"
                            time={this.props.baseTime}
                            onChange={this.setBaseTime}
                            closeOnSelect={true} />
                    </Form.Group>
                </Form>
            </div>
        );
    }

}

export default TimeParametersStep