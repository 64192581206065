import React from 'react'

import { Map } from 'immutable';

import InfoPanel from './info-panel'
import Container from 'react-bootstrap/Container'
import AccessMap, { SECTOR_SELECTION_MODE } from './map'
import {
    setCalculationView,
    getClearedCalculationState,
    isEntirelyExhaustive,
} from './calculation'
import {
    setViewport,
    setSector,
    setExpandedSector,
    clearExpandedSector,
    setTransformationType,
    updateAggregationsForDuration,
} from './access-management'
import { RingSpinnerOverlay } from 'react-spinner-overlay';
import { DefaultColorScheme } from './color';
import { Modal } from 'react-bootstrap';

class AccessViewer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showInfo: this.props.initialShowInfo,
            expansionUpdateInterval: undefined,
            durationUpdateInterval: undefined,

            "sectors": [],
            "gridId": undefined,
            "gridName": undefined,
            "gridCenter": undefined,

            "serviceId": undefined,
            "earliestTime": undefined,
            "latestTime": undefined,

            "calculation": Map(getClearedCalculationState()),
            "baseline": Map(getClearedCalculationState()),

            "selectedSector": undefined,
            "boundsSelection": undefined,
            "clickedLocation": undefined,

            "expandedSector": undefined,

            "loadingExpansion": false,
            "changingDuration": false,

            "selectedSectorAggregation": undefined,

            "viewport": {
                "latitude": 39.8283,
                "longitude": -98.5795,
                "zoom": 13,
            },

            "transformationType": undefined,
            "sectorTransformations": Map(),
            "expandedSectorTransformations": Map(),
            "colorScheme": new DefaultColorScheme(),

            inServiceSeconds: 0,
            baselineInServiceSeconds: 0
        };

        this.loadCalculationPromise = (calculation, calculationPath, baseline,
            baselinePath) => setCalculationView(this, calculation,
                calculationPath, baseline, baselinePath);

        this.setViewport = (viewport) => setViewport(this, viewport);

        this.setSector = (sectorId) => setSector(this, sectorId);

        this.setExpandedSector = (sectorId) => setExpandedSector(this, sectorId);

        this.clearExpandedSector = () => clearExpandedSector(this);

        this.setTransformationType = (type) => setTransformationType(this, type);

        this.setDuration = (duration) => {
            updateAggregationsForDuration(
                this,
                duration,
                this.state.calculation.get("id"),
                this.state.calculation.get("path"),
                "calculation",
                "inServiceSeconds");
        }

        this.setBaselineDuration = (duration) => {
            updateAggregationsForDuration(
                this,
                duration,
                this.state.baseline.get("id"),
                this.state.baseline.get("path"),
                "baseline",
                "baselineInServiceSeconds");
        }

        this.closeInfo = () => {
            this.setState({ "showInfo": false });
        }

    }

    componentDidMount() {
        this.loadCalculationPromise(this.props.calculationId, this.props.path,
            this.props.baselineCalculationId, this.props.baselinePath);
    }

    render() {
        const infoModal = (
            <Modal centered={true}
                size="lg"
                show={this.state.showInfo}
                onHide={this.closeInfo}>
                <Modal.Header closeButton>
                    <Modal.Title>Network Access Map</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This map shows a geographic breakdown of overall transit access in Seattle.
                        It is created by first dividing the city into an 80 meter by 80 meter grid of sectors.
                        Using a pathfinding algorithm that incorporates transit schedules and walkable paths,
                        isochrones are generated for the combination of every sector and minute of a given day.
                        This map can be thought of as the result of overlaying all the resultant isochrones and adding up every time a sector is a reachable destination within the time budget.
                        Areas where there is the most overlap can be reached from the most places and times.
                    </p>
                    <p>
                        Several per-sector scores are derived from this process.
                        They can be selected from the menu to the left of the scale at the bottom, and are described in <a href="https://busgraphs.com/page/glossary#score">this glossary</a>.
                    </p>
                    <p>The isochrone computation is done for a variety of time budgets.
                        The budget to view can be selected from the control on the upper left of the page.</p>
                    <p>Zooming in will reveal the transit stops and routes (colored arbitrarily) active during the measured span of service.
                        Zooming in further shows each sector's rank and value under the currently selected measurement.</p>
                    <p>
                        Click a sector once to select it and see its precise access measurements.
                        With the sector selected, click it again to expand it.
                        This shows the composite of all the isochrones centered about the sector.
                        (Unlike most isochrone maps, color is not used to show different durations, but how often throughout the day the destination is reachable.
                        This is in contrast to those that decribe a single time of day or set a percentile threshold at which a destination is considered reachable.)
                        While a sector is expanded, selecting other sectors also shows their access with respect to the expanded sector.
                        Click the expanded sector, outlined in gray, again to return to the unexpanded view.
                    </p>
                </Modal.Body>
            </Modal>
        )

        return (
            <>
                {infoModal}
                <RingSpinnerOverlay
                    loading={this.state.sectorTransformations.isEmpty()}
                    color="#000000" />
                <Container fluid style={{
                    "display": "flex",
                    "flexDirection": "column",
                    "flexGrow": 1
                }}>

                    <h1>{this.state.calculation.get("name")}</h1>
                    <InfoPanel
                        colorScheme={this.state.colorScheme}
                        transformationType={this.state.transformationType}
                        sectors={this.state.sectors}
                        selectedSector={this.state.selectedSector}
                        expandedSector={this.state.expandedSector}
                        setSector={this.setSector}
                        calculation={this.state.calculation}
                        baseline={this.state.baseline}
                        unScoreIt={this.props.unScoreIt}
                        setDuration={this.setDuration}
                        setBaselineDuration={this.setBaselineDuration}
                        inServiceSeconds={this.state.inServiceSeconds}
                        baselineInServiceSeconds={this.state.baselineInServiceSeconds} />
                    <AccessMap
                        minRouteZoom={14}
                        stops={this.state.calculation.get("stops")}
                        linestrings={this.state.calculation.get("linestrings")}
                        routesForRoutings={this.state.calculation.get("routesForRoutings")}
                        gridId={this.state.gridId}
                        colorScheme={this.state.colorScheme}
                        sectorTransformations={this.state.sectorTransformations}
                        expandedSectorTransformations
                        ={this.state.expandedSectorTransformations}
                        transformationType={this.state.transformationType}
                        gridCenter={this.state.gridCenter}
                        calculationCenterPoints
                        ={this.state.calculation.get('centerPoints')}
                        baselineCenterPoints
                        ={this.state.baseline.get('centerPoints')}
                        calculationCenterSectors
                        ={this.state.calculation.get('centerSectors')}
                        baselineCenterSectors
                        ={this.state.baseline.get('centerSectors')}
                        setSector={this.setSector}
                        selectedSector={this.state.selectedSector}
                        expandedSector={this.state.expandedSector}
                        busy={this.state.loadingExpansion || this.state.changingDuration}
                        setExpandedSector={this.setExpandedSector}
                        clearExpandedSector={this.clearExpandedSector}
                        unGridIt={this.props.unGridIt}
                        showSelect={!this.state.gridId}
                        selectionMode={SECTOR_SELECTION_MODE}
                        viewport={this.state.viewport}
                        setViewport={this.setViewport}
                        isComparison={!!this.state.baseline.get('id')}
                        isExhaustive={isEntirelyExhaustive(
                            this.state.calculation, this.state.baseline)}
                        setTransformationType={this.setTransformationType}
                        boundaryFindingMode={false} />
                    <small><a href={process.env.PUBLIC_URL + "/Open Source Disclosure.pdf"}>Open source disclosure</a></small>
                </Container>
            </>);
    }
}

export default AccessViewer;